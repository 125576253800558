<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div
    v-if="allReminders.length > 0"
    class="goals-wrapper"
  >
    <div class="flex header">
      <h6
        class="x-big-semi margin margin-bottom margin-24px"
      >
        {{ isEditMode ? $t('Components.Wellness.edit_goal') : $t('Components.Wellness.select_goal') }}
      </h6>
      <div
        v-if="!isEditMode"
        class="add-btn-wrapper"
        @click="editMode = true"
      >
        <svg-icon src="/icons/pen.svg" />
      </div>
      <div
        v-else
        class="add-btn-wrapper"
        @click="addReminder"
      >
        <svg-icon src="/icons/add.svg" />
      </div>
    </div>
    <div
      v-if="isEditMode"
      class="goals-card flex default-goals"
    >
      <h6>
        {{ $t('Components.Wellness.default_goal') }}
      </h6>
      <p>
        {{ $t('Components.Wellness.not_editable') }}
      </p>
    </div>
    <div
      v-for="reminder in allReminders"
      v-if="(reminder.isdefault !== 1 && isEditMode) || !isEditMode"
      :key="reminder.id"
      class="goals-card flex"
      :class="{'last': reminder.id === allReminders[allReminders.length - 1].id && !isEditMode}"
    >
      <div
        class="distribution-bar__holder flex"
      >
        <div class="reminder-title-holder flex">
          <b-form-input
            v-if="isEditMode"
            ref="reminder-name-input-ref"
            class="reminder-name--input"
            :debounce="300"
            :value="reminder.name"
            :state="reminder.name.length > 30 ? false : null"
            size="sm"
            @update="setReminderName(reminder.id, ...arguments)"
          />
          <h6
            v-else
            class="reminder-title"
          >
            {{ reminder.name }}
          </h6>
          <div
            v-if="isEditMode"
            class="remove-icon-wrapper"
            @click="deleteReminder(reminder.id)"
          >
            <svg-icon
              src="/icons/delete_cross.svg"
            />
          </div>
        </div>
        <div class="flex">
          <DistributionBar
            :time-ms-one="reminder.sittime"
            :time-ms-two="reminder.standtime"
            :selected="isActive(reminder) || isEditMode"
            :editmode="isEditMode"
            :class="isEditMode ? 'width-semi' : 'width-full'"
            @updated-sit-time="sitTimeChanged(reminder.id, ...arguments)"
            @updated-stand-time="standTimeChanged(reminder.id, ...arguments)"
          />
          <div
            v-if="isActive(reminder) && isEditMode"
            class="active-icon-wrapper"
          >
            <svg-icon
              src="/icons/check-solid.svg"
            />
          </div>
        </div>
      </div>
      <div
        class="radiobutton-selector"
        :class="{'hide-toggle': isEditMode}"
        @click="selectReminder(reminder)"
      >
        <RadioButton
          :class="goalSelected === reminder.id ? 'checked' : 'not-checked'"
        />
      </div>
    </div>
    <div />
    <div
      v-if="isEditMode"
      class="flex"
      style="justify-content: space-between;"
    >
      <Button
        variant="danger"
        size="small"
        @click="cancelEdit"
      >
        {{ $t('Components.Wellness.cancel') }}
      </Button>
      <Button
        variant="primary"
        size="small"
        :disabled="!enableSaveButton"
        @click="saveReminders"
      >
        {{ $t('Components.Wellness.save') }}
      </Button>
    </div>
  </div>
</template>

<script>

/* Models */
import RadioButton from '@/components/RadioButton/simple.radio.button.vue';
import RemindersModel from '@/models/reminders/reminders.model';

/* Singletons */
import ReminderState from '@/singletons/reminder.state.singleton';

/* Components */
import DistributionBar from '@/components/distributionbar/distributionbar.vue';
import Button from '@/components/button/button.vue';
import { BFormInput } from 'bootstrap-vue';

export default {

  components: {
    BFormInput,
    DistributionBar,
    Button,
    RadioButton,
  },

  data() {
    return {
      Reminders: RemindersModel,
      goalSelected: null,
      tempIDAutoInc: 0,
      editMode: false,
      reminders: [],
      selected: [],
    };
  },

  computed: {
    isEditMode() {
      return this.editMode;
    },
    allReminders() {
      return this.reminders.filter((rem) => (rem.wasAdded === true && rem.removed !== true) || (rem.deletedat === null && rem.removed !== true));
    },
    defaultRemindersFilteredOut() {
      return this.allReminders.filter((rem) => rem.isdefault !== 1);
    },
    enableSaveButton() {
      return this.reminders.some((rem) => {
        if (rem.name.length <= 0) return false;
        if (rem.standtime <= 0) return false;
        if (rem.sittime <= 0) return false;
        if (rem.hasChanged) return true;
        if (rem.wasAdded) return true;
        if (rem.removed) return true;
        return false;
      });
    },
  },

  watch: {
    'Reminders.cachedReminders': {
      deep: true,
      handler() {
        this.setLocalReminders(this.Reminders.cachedReminders);
      },
    },
    'Reminders.reminders': {
      deep: true,
      handler() {
        this.setLocalReminders(this.Reminders.reminders);
      },
    },
  },

  created() {
    this.setLocalReminders(this.Reminders.reminders);
  },

  mounted() {
    this.goalSelected = RemindersModel.currentlySelectedReminderID;
  },

  methods: {
    async cancelEdit() {
      this.editMode = false;
      await this.Reminders.init();
      this.setLocalReminders(this.Reminders.reminders);
    },
    addReminder() {
      // Going negative to not conflict with existing IDs
      this.tempIDAutoInc -= 1;

      this.Reminders.cachedReminders.push({
        id: this.tempIDAutoInc.toString(),
        name: this.$t('Components.Wellness.new_reminder'),
        deletedat: null,
        sittime: 3600000,
        standtime: 1800000,
        profileid: 1, // Just stay 1 for now, not sure this is needed
        isdefault: 0,
        wasAdded: true,
      });
      this.setLocalReminders(this.Reminders.cachedReminders);

      // Highlights the "name"-input of the newly added reminder
      this.$nextTick(() => {
        // This makes sure we always select the latest added "cashed" reminder
        this.$refs['reminder-name-input-ref'][this.$refs['reminder-name-input-ref'].length - 1].select();
      });
    },
    setLocalReminders(reminders) {
      this.reminders = [...reminders];
    },
    deleteReminder(id) {
      const reminder = this.Reminders.cachedReminders.filter((rem) => rem.id === id)[0];
      if (reminder) {
        reminder.removed = true;
        reminder.deletedat = Date.now();
      }
      this.setLocalReminders(this.Reminders.cachedReminders);
    },
    setReminderName(id, name) {
      let hasError = false;
      if (name.length <= 0 || name.length > 30) {
        hasError = true;
      }

      this.Reminders.cachedReminders.filter((rem) => rem.id === id)[0].name = name;
      this.Reminders.cachedReminders.filter((rem) => rem.id === id)[0].hasChanged = true;
      this.Reminders.cachedReminders.filter((rem) => rem.id === id)[0].hasError = hasError;

      this.setLocalReminders(this.Reminders.cachedReminders);
    },
    saveReminders() {
      this.editMode = false;

      this.Reminders.cachedReminders.forEach((reminder) => {
        let rmdr;
        if (reminder.hasError) {
          rmdr = reminder;
        } else if (reminder.removed) {
          rmdr = RemindersModel.deleteReminder(reminder.id);
          if (this.goalSelected === reminder.id) {
            this.selectReminder(this.Reminders.reminders[0]);
          }
        } else if (reminder.wasAdded) {
          rmdr = RemindersModel.insertReminder(
            reminder.name, reminder.sittime, reminder.standtime, reminder.profileid, reminder.isdefault,
          );
        } else if (reminder.hasChanged) {
          rmdr = RemindersModel.updateReminder(reminder);
          if (this.goalSelected === reminder.id) {
            this.selectReminder(reminder);
          }
        }
        return rmdr;
      });

      this.setLocalReminders(this.Reminders.reminders);
    },
    standTimeChanged(id, timeMs) {
      this.Reminders.cachedReminders.filter((rem) => rem.id === id)[0].standtime = timeMs;
      this.Reminders.cachedReminders.filter((rem) => rem.id === id)[0].hasChanged = true;
      this.setLocalReminders(this.Reminders.cachedReminders);
    },
    sitTimeChanged(id, timeMs) {
      this.Reminders.cachedReminders.filter((rem) => rem.id === id)[0].sittime = timeMs;
      this.Reminders.cachedReminders.filter((rem) => rem.id === id)[0].hasChanged = true;
      this.setLocalReminders(this.Reminders.cachedReminders);
    },
    isActive(reminder) {
      if (this.editMode) {
        return this.goalSelected?.toString() === reminder.id.toString() ? !!(reminder.isdefault === 0) : false;
      }
      return this.goalSelected?.toString() === reminder.id.toString();
    },
    async selectReminder(reminder) {
      this.selected = reminder;
      ReminderState.selectReminder(reminder);
      this.$nextTick(() => {
        this.goalSelected = reminder.id;
      });
    },
  },

};

</script>

<style lang="scss">

  .goals-wrapper {
    .default-goals {
      justify-content: space-between;
    }
    .goals-card {
      justify-content: space-between;

      margin-bottom: 1rem;
      padding-bottom: 1rem;
      &:not(.last) {
        border-bottom: 1px solid var(--placeholder-font-color);
      }

      .remove-icon-wrapper {
        fill: var(--color-resource-other);
        height: 1rem;
        width: 1rem;
        cursor: pointer;
        margin-left: 1rem;

        &:hover {
          fill: var(--color-sensor)
        }
      }

      .active-icon-wrapper {
        fill: var(--primary-color);
        height: 1rem;
        width: 1rem;
        margin-left: 1rem;
        margin-top: 0.25rem;
      }

      .distribution-bar__holder {
        width: 100%;
        flex-direction: column;
        transition: width 0.2s ease;

        .reminder-title-holder {
          .reminder-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .width-semi {
          width: 90%;
        }

        .width-full {
          width: 100%;
        }

        .reminder-name--input {
          width: 90%;
          margin-bottom: 0.5rem;
        }

        .icon-margin-left {
          margin-left: 1rem;
        }
      }

      .radiobutton-selector {
        margin-top: 1.8rem;
        width: 15%;
        transform: scale(1);
        transition: transform 0.2s ease;
        transition: width 0.2s ease;

        * {
          margin-left: auto;
        }

        &.hide-toggle {
          transform: scale(0);
          width: 0%;
        }
      }
    }

    .header {
      padding: 0 auto;
      justify-content: space-between;
    }

    .add-btn-wrapper {
      background-color: var(--primary-color-light);
      fill: var(--primary-color);
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;

      &:hover {
        background-color: var(--primary-color-medium-light);
        cursor: pointer;
      }

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

</style>
