<template>
  <div
    class="wellness-wrapper"
  >
    <!-- Homing -->
    <template v-if="device && device.isHoming">
      <Header
        class="flex"
      >
        <h5 class="center-text header-title error">
          {{ $t('Components.WellnessError.homing_error_header') }}
        </h5>
      </Header>
      <Card>
        <HomingError />
      </Card>
    </template>

    <!-- Connection Error -->
    <template v-else-if="device && device.connectionFailed">
      <Header
        class="flex"
      >
        <h5 class="center-text header-title error">
          {{ $t('Components.WellnessError.connection_error_header') }}
        </h5>
      </Header>
      <Card>
        <UnplugError />
      </Card>
    </template>

    <!-- Connecting -->
    <template v-if="device && !device.ready && !device.connectionFailed && !device.isHoming">
      <Header
        class="flex"
      >
        <h5 class="center-text header-title">
          {{ $t('Components.Controller.connecting') }}
        </h5>
      </Header>
      <Card>
        <Connecting
          :device="device"
        />
      </Card>
    </template>

    <!-- Timer -->
    <template v-else>
      <!-- <template> -->
      <Header
        class="flex wellness-header"
        :title="$t('Components.Wellness.header')"
      >
        <template>
          <h5 class="center-text header-title">
            {{ $t('Components.Wellness.header') }}
          </h5>
        </template>
        <Button
          variant="pastel-primary"
          size="tiny"
          @click="navigate()"
        >
          <div class="wellness-settings--button">
            <svg-icon
              src="/icons/settings.svg"
            />
          </div>
        </Button>
      </Header>

      <div class="scrollable">
        <!-- Sub Header with toggle button-->
        <Card position="bottom">
          <div style="display: flex; justify-content: space-between; ">
            <label>{{ $t('Components.Wellness.ergonomic_timer') }}</label>
            <BFormCheckbox
              v-model="isReminderActive"
              switch
              size="lg"
              @change="onReminderChange"
            />
          </div>
        </Card>

        <Card>
          <!-- Reminder / Timer -->
          <ReminderTimer @onClick="startReminder" />

          <!-- Reminder Actions -->
          <ReminderActions
            v-if="isReminderFinished"
            @snooze="snoozeReminder"
          />
        </Card>

        <!-- Reminder Items -->
        <Card position="bottom">
          <ReminderItems />
        </Card>
      </div>
    </template>
  </div>
</template>

<script>

/** State */
import ConnectState from '@/singletons/connect.state.singleton';
import ReminderState, { REMINDER_STATE_FINISHED } from '@/singletons/reminder.state.singleton';

/** Components */
import Header from '@/components/headers/header.vue';
import Button from '@/components/button/button.vue';
import Card from '@/components/card/card.vue';
import { BFormCheckbox } from 'bootstrap-vue';
import UnplugError from '@/components/errors/unplug.error.vue';
import HomingError from '@/components/errors/homing.error.vue';
import Connecting from '@/views/device.controller/components/connecting.vue';
import ReminderTimer from './components/reminder.timer.vue';
import ReminderActions from './components/reminder.actions.vue';
import ReminderItems from './components/reminder.items.vue';

export default {
  components: {
    Card,
    Header,
    Button,
    ReminderTimer,
    Connecting,
    BFormCheckbox,
    ReminderItems,
    ReminderActions,
    UnplugError,
    HomingError,
  },

  data() {
    return {
      ReminderState,
      isReminderActive: !!(ReminderState?.profile?.reminder > 0),
      Device: ConnectState.selectedDevice,
    };
  },

  computed: {

    device: {
      deep: true,
      get() {
        return this.Device;
      },
    },

    isReminderFinished() {
      return ReminderState?.reminder?.state === REMINDER_STATE_FINISHED;
    },

    isConnected() { return ConnectState?.selectedDevice || false; },

  },

  watch: {
    'AppModel.profile.reminder': function a() {
      this.isReminderActive = !!(ReminderState?.profile?.reminder > 0);
    },
    'ReminderState.profile.reminder': {
      deep: true,
      handler() {
        this.isReminderActive = !!(ReminderState?.profile?.reminder > 0);
      },
    },
  },

  async mounted() {
    this.goalSelected = ReminderState.profile?.reminderid;
  },

  methods: {
    startReminder() {
      ReminderState.startTimer();
    },

    snoozeReminder(ms) {
      if (ms) {
        ReminderState.createSnoozeState(ms);
      } else {
        // Just restart current timer
        ReminderState.startTimer();
      }
    },

    onReminderChange(active) {
      if (this.reminderActive === active) return;

      if (active) {
        ReminderState.startTimer();
      } else {
        ReminderState.stopTimer();
      }
    },
    navigate() {
      this.$router.push('/wellness/settings');
    },

    timeString(millis) {
      const hour = 1000 * 60 * 60;
      const minute = 1000 * 60;
      const second = 1000;
      const hours = Math.floor(millis / hour);
      const minutes = Math.floor((millis - (hours * hour)) / minute);
      const seconds = Math.floor((millis - (minutes * minute)) / second);
      if (hours === 0 && minutes === 0) return `${seconds}s`;
      if (hours === 0) return `${minutes}${minutes === 1 ? 'min' : 'mins'}`;
      return `${hours}h ${minutes}${minutes === 1 ? 'min' : 'mins'}`;
    },
  },
};
</script>

<style lang="scss">
  .wellness-wrapper {

    .wellness-settings--button {
      width: 1.5rem;
      height: 1.5rem;
    }
    .wellness-header {
      * {
        margin: auto 0;
      }
      .btn {
        justify-self: flex-end;
        display: none;
        padding: 0rem;
        width: 2rem;
        height: 2rem;
        * {
          margin: 0 auto;
        }
      }
    }

    .list-item-wrapper {
      .btn  {
        align-self: center;
      }
      .list-item-icon-text-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
</style>
