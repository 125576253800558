<template>
  <div
    class="dot-typing"
    :class="mode"
  />
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: 'normal',
    },
  },
};
</script>

<style lang="scss" scoped>
.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-color-light);
  color: var(primary-color);
  &.normal{
    box-shadow: 9999px 0 0 0 var(--primary-color-light), 9999px 0 0 0 var(--primary-color-light), 10029px 0 0 0 var(--primary-color-light);
    animation: dotTyping 1.5s infinite linear;
  }
  &.reverse{
    box-shadow: 9969px 0 0 0 var(--primary-color-light), 9984px 0 0 0 var(--primary-color-light), 9999px 0 0 0 var(--primary-color-light);
    animation: dotTypingReverse 1.3s infinite linear;
  }
}

@keyframes dotTyping {
  0% {
    box-shadow: 9999px 0 0 0 var(--primary-color), 10014px 0 0 0 var(--primary-color), 10029px 0 0 0 var(--primary-color-light);
  }
  16% {
    box-shadow: 9999px -10px 0 0 var(--primary-color-light), 10014px 0 0 0 var(--primary-color), 10029px 0 0 0 var(--primary-color);
  }
  33% {
    box-shadow: 9999px -10px 0 0 var(--primary-color-light), 10014px -5px 0 0 var(--primary-color-light), 10029px 0 0 0 var(--primary-color);
  }
  50% {
    box-shadow: 9999px 0 0 0 var(--primary-color-light), 10014px -10px 0 0 var(--primary-color-light), 10029px 0 0 0 var(--primary-color);
  }
  66% {
    box-shadow: 9999px 0 0 0  var(--primary-color), 10014px -5px 0 0 var(--primary-color-light), 10029px -10px 0 0 var(--primary-color-light);
  }
  83% {
    box-shadow: 9999px 0 0 0  var(--primary-color), 10014px 0 0 0 var(--primary-color-light), 10029px -10px 0 0 var(--primary-color-light);
  }
  100% {
    box-shadow: 9999px 0 0 0  var(--primary-color), 10014px 0 0 0  var(--primary-color), 10029px 0 0 0 var(--primary-color-light);
  }
}

@keyframes dotTypingReverse {
  0% {
    box-shadow: 9969px 0 0 0 var(--primary-color), 9984px 0 0 0 var(--primary-color), 9999px 0 0 0 var(--primary-color-light);
  }
  16% {
    box-shadow: 9969px -10px 0 0 var(--primary-color-light), 9984px 0 0 0 var(--primary-color), 9999px 0 0 0 var(--primary-color);
  }
  33% {
    box-shadow: 9969px -10px 0 0 var(--primary-color-light), 9984px -5px 0 0 var(--primary-color-light), 9999px 0 0 0 var(--primary-color);
  }
  50% {
    box-shadow: 9969px 0 0 0 var(--primary-color-light), 9984px -10px 0 0 var(--primary-color-light), 9999px 0 0 0 var(--primary-color);
  }
  66% {
    box-shadow: 9969px 0 0 0  var(--primary-color), 9984px -5px 0 0 var(--primary-color-light), 9999px -10px 0 0 var(--primary-color-light);
  }
  83% {
    box-shadow: 9969px 0 0 0  var(--primary-color), 9984px 0 0 0 var(--primary-color-light), 9999px -10px 0 0 var(--primary-color-light);
  }
  100% {
    box-shadow: 9969px 0 0 0  var(--primary-color), 9984px 0 0 0  var(--primary-color), 9999px 0 0 0 var(--primary-color-light);
  }
}

</style>
