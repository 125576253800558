<template>
  <div>
    <Header
      class="flex"
      :title="$t('Views.Book.book')"
    >
      <h5 class="header-title">
        {{ $t('Views.Book.book') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <WeeklyDateSelector
          :week-length="WeekState.weekLength"
          :week-start="WeekState.date"
          :booking-notifications="bookingNotifications"
          @date-selected="dateChanged"
          @week-changed="weekChanged"
        />
      </Card>
      <Card position="bottom no-padding-top">
        <div
          v-for="(group, key, index) in zones"
          :key="key"
        >
          <h6
            v-if="key"
            class="normal-semi"
            :style="index === 0 ? 'margin-top: 2rem' : ''"
          >
            {{ key }}
          </h6>
          <div
            v-for="(typeV, typeK) in group"
            :key="typeK"
          >
            <ResourceType
              :resources="typeV"
              :type="typeK"
            />
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import WeekState from '@/singletons/week.state.singleton';
import WeeklyDateSelector from '@/components/weeklydateselector/weekly.dateselector.vue';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import UserState from '@/singletons/user.state.singleton';

import BookingService from '@/Services/Bookings/bookings.service';
import { sortObj } from '@/functions/helper.functions';
import UserService from '@/Services/User/user.service';
import CompanyState from '@/singletons/company.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import { getBookingNotifications } from '@/functions/date.notification.functions';
import ResourceType from './components/resource.type.item.vue';
import QueryMixin from '../../mixins/query.mixin';
import ExtendedDate from '../../classes/extended.date.class';

export default {
  components: {
    Header,
    Card,
    WeeklyDateSelector,
    ResourceType,
  },
  mixins: [QueryMixin],
  data() {
    return {
      WeekState,
      CompanyState,
      ZoneState,
      bookingNotifications: [],
    };
  },
  computed: {
    zones() {
      const obj = sortObj(CompanyState.zonesOnFloor);
      delete obj.Space;

      return obj;
    },
    bookings() {
      const now = new ExtendedDate();
      const bookings = UserState.bookings.filter((b) => WeekState.date.isSameDate(new ExtendedDate(b.From)) && !now.isBefore(b.until));
      return bookings;
    },
  },
  async created() {
    const { date } = this.$route.query;
    const checkedDate = date && date !== 'undefined' ? new ExtendedDate(date) : new ExtendedDate();
    await this.weekChanged(checkedDate);
    ZoneState.resourceTypeFilter = null;
  },
  methods: {
    async weekChanged(date) {
      WeekState.changeWeek(date);
      this.replaceQuery({ date: WeekState.date.getDateString() });
      this.bookingNotifications = [];
      await Promise.all([
        UserService.fetchUserBookings(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime()).then(() => {
          this.bookingNotifications = getBookingNotifications(UserState.bookings, WeekState.weekLength);
        }),
        BookingService.getBookingsOfDay(date),
      ]);
    },
    async dateChanged(date) {
      WeekState.date = date;
      this.replaceQuery({ date: WeekState.date.getDateString() });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
