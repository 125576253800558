<template>
  <div class="resource-type-header_container margin margin-bottom margin-top margin-medium-large">
    <!--eslint-disable vue/no-use-v-if-with-v-for -->
    <div
      class="left-side"
      @click="goToResourceGroup()"
    >
      <RoundResourceIcon
        :src="resources[0].Icon || '/icons/default_resource.svg'"
        type="resource"
      />
      <div class="status-container">
        <h6>{{ resources[0].TypeName }}</h6>
        <div class="dots-container">
          <div
            v-for="(state, index) in states"
            v-if="state > 0 "
            :key="index"
            class="dot-container"
            :class="`order-${index}`"
          >
            <div
              class="dot"
              :class="statesToCssClass(index)"
            />
            <p class="small-regular">
              {{ state }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="primary-color-icon noselect"
      @click="addQueryType()"
    >
      <svg-icon
        src="/icons/map_navigation_pin.svg"
      />
    </div>
  </div>
</template>

<script>

import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import ZoneState from '@/singletons/zone.state.singleton';
import MapState from '@/singletons/map.state.singleton';
import QueryMixin from '@/mixins/query.mixin';
import { getResourcesStateCount } from '@/functions/resources.functions';
import PhoneService from '@/Services/Phone/phone.service';

export default {
  components: {
    RoundResourceIcon,
  },
  mixins: [QueryMixin],
  props: {
    resources: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      states: null,
      ZoneState,
      MapState,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'ZoneState.zoneStateChanged': function () {
      this.states = getResourcesStateCount(this.resources);
    },
  },
  async mounted() {
    this.states = getResourcesStateCount(this.resources);
  },
  methods: {
    addQueryType() {
      if (PhoneService.isInPhoneApp()) {
        this.$router.push({ name: 'map', query: { type: this?.type } });
      } else {
        this.updateQuery({ type: this.type });
      }
    },
    goToResourceGroup() {
      this.$router.push({ path: `/book/${this.type}`, query: { date: this.$route.query.date, ignoreType: false } });
    },
    statesToCssClass(state) {
      switch (state) {
        case 0:
        case '0':
          return 'unkown';
        case 1:
        case '1':
          return 'available';
        case 2:
        case '2':
          return 'away';
        case 3:
        case '3':
          return 'occupied';
        default:
          return 'unkown';
      }
    },
  },
};
</script>

<style lang="scss">
.resource-type-header_container{
  display: flex;
  justify-content: space-between;
  &:hover{
    cursor: pointer;
  }
  .primary-color-icon {
    margin: auto 0;
    svg{
      fill: var(--primary-color)
    }
  }
  .left-side{
    flex: 1 1 auto;
    display: flex;
    gap: 1.5rem;
    h6{
      margin: auto 0;
    }
    .status-container{
      display: flex;
      flex-direction: column;
      .dots-container{
        display: flex;
        justify-content: flex-start;
        gap: 1.5rem;

        &.unkown {
          order: 4;
        }

        .dot-container{
          display: flex;
          margin: auto 0;
          gap: 0.3rem;

          &.order-0 {
            order: 4;
          }

          p{
            margin: auto;
          }
          .dot {
            margin: auto;
            height: 0.8125rem;
            width: 0.8125rem;
            border-radius: 50%;
          &.available{
            background-color: var(--free-color);
            }
          &.away{
            background-color: var(--away-color);
            }
          &.unkown{
            background-color: var(--other-color);
            }
          &.occupied{
            background-color: var(--occupied-color);
            }
          }
        }
      }
    }
  }
}
</style>
