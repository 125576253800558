<template>
  <div
    class="simple--radio-btn flex flex-center"
    :class="type"
  >
    <div
      class="radio-btn__fill"
      :class="type"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'resource',
    },
  },

};
</script>

<style lang="scss" scoped>
  .simple--radio-btn {
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    width: 2.25rem;
    height: 2.25rem;
    &.space{
      border: 1px solid var(--space-color);
    }
    &:hover{
      cursor: pointer;
    }
    &.checked {
      .radio-btn__fill {
        margin: auto;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        &.space{
          background-color: var(--space-color);
        }
        background-color: var(--primary-color);
      }
    }
    &.not-checked {
      .radio-btn__fill {
        display: none;
      }
    }
  }
</style>
