<template>
  <div>
    <div
      v-if="!showSnoozeOptions"
      class="reminder-action__container"
    >
      <Button
        size="small"
        variant="primary-reverse"
        @click="showSnooze"
      >
        {{ $t('Components.Wellness.snooze') }}
      </Button>
      <Button
        size="small"
        @click="changePosition"
      >
        {{ $t('Components.Wellness.change_position') }}
      </Button>
    </div>
    <div
      v-else
      class="reminder-action__container"
    >
      <Button
        variant="primary-reverse"
        size="tiny"
        @click="snooze()"
      >
        {{ $t('Components.Wellness.repeat') }}
      </Button>
      <Button
        variant="primary-reverse"
        size="tiny"
        @click="snooze(snoozeTime(10))"
      >
        {{ $t('Components.Wellness.10_min') }}
      </Button>
      <Button
        variant="primary-reverse"
        size="tiny"
        @click="snooze(snoozeTime(30))"
      >
        {{ $t('Components.Wellness.30_min') }}
      </Button>
      <Button
        variant="danger"
        size="tiny"
        @click="showSnooze"
      >
        {{ $t('Components.Wellness.cancel') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/button.vue';

import ConnectState from '@/singletons/connect.state.singleton';
import AppModel from '@/singletons/application.state.singleton';

import ReminderState from '@/singletons/reminder.state.singleton';

import UserState from '@/singletons/user.state.singleton';

export default {
  components: {
    Button,
  },

  data() {
    return {
      ConnectState,
      AppModel,
      showSnoozeOptions: false,
    };
  },

  computed: {
    device() { return ConnectState.selectedDevice; },

    positionHeight() { return AppModel.reminder.position.height; },

    targetPosition() {
      const shouldMoveTo = ReminderState?.reminder?.targetPosition;
      const moveToHeight = shouldMoveTo === 'standing' ? UserState.profile.StandHeight : UserState.profile.SitHeight;
      console.log('SHOULDMOVE TO: ', shouldMoveTo);
      return moveToHeight || null;
    },

    name() { return this.targetPosition?.name; },

    height() { return this.targetPosition?.height; },

  },

  methods: {
    showSnooze() {
      if (this.showSnoozeOptions) {
        this.showSnoozeOptions = false;
      } else {
        this.showSnoozeOptions = true;
      }
    },
    changePosition() {
      if (!this.device) {
        UserState.currentPosition = UserState.currentPosition === 'sitting' ? 'standing' : 'sitting';
        ReminderState.handlePositionChanged();
      } else {
        this.$router.push({ path: '/controller', query: { moveToPosition: this.targetPosition?.toString() } });
      }
    },
    snooze(ms) {
      this.showSnoozeOptions = false;
      this.$emit('snooze', ms);
    },
    snoozeTime(min) {
      return 1000 * 60 * min;
    },
  },
};
</script>

<style lang="scss" scoped>
.reminder-action__container {
  display: flex;
  justify-content: space-between;
}
</style>
