<template>
  <div>
    <img
      class="error_homing_image"
      src="images/homing.png"
    >

    <div class="error_homing_info">
      <svg-icon src="/icons/error_icon.svg" />
      <div class="text">
        <p>
          {{ $t('Components.WellnessError.homing_error') }}
        </p>
        <p>
          {{ $t('Components.WellnessError.homing_error_2') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
.error_homing_image{
  margin : 0 auto;
  display: block;
  width: 70%;
}
.error_homing_info{
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
  svg{
    fill: var(--color-red-primary);
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
