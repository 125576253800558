<template>
  <div class="content__container">
    <!-- Info row -->
    <div
      class="connecting__info reverse"
    >
      <div>
        <h3> {{ $t('Components.Controller.connecting') }} </h3>
        <div class="loader">
          <LoadingDots mode="reverse" />
        </div>
      </div>
    </div>
    <!-- image  -->
    <div
      class="content__visual"
    >
      <DeskImage
        class="image-top-margin"
        src="images/desk.png"
      />
    </div>
  </div>
</template>

<script>
import DeskImage from '@/components/desk.image.vue';
import LoadingDots from '../../../components/loaders/loading.dots.vue';

export default {
  components: {
    DeskImage,
    LoadingDots,
  },
};
</script>

<style lang="scss">
@import '../style/controller.scss';

.content__visual{
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  .image-top-margin{
    margin-top: 3rem;
  }
  .height-bar__container{
    position: absolute;
    right: 0;
    top:0;
    margin-right: 3rem;
    padding: 1rem;
  }
}

.connecting__info {
  display: flex;
  justify-content: space-between;
  &.reverse{
    flex-direction: row-reverse;
      .loader{
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
      }
    .position-selection_container{
      display: flex;
      flex-direction: column;
      h3, h6{
        display: flex;
        justify-content: flex-end;
      }

    }
  }
  h6 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  h3 {
    margin-bottom:auto;
    display: flex;
    align-items: flex-end;
    font-size: 2rem;
    color: var(--primary-font-color);
    font-variant-numeric: tabular-nums;
  }
}
</style>
