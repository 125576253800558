<template>
  <div>
    <div class="goal-distributionbar__container">
      <div class="goal-distributionbar__background" />
      <div
        class="goal-distributionbar__foreground"
        :class="{'grayed-out': !selected}"
        :style="selected ? `background: linear-gradient(to right, ${linearGradientStyle});` : null"
      />
    </div>
    <div class="goal-distributionbar__values-holder">
      <div class="label--wrapper left">
        <svg-icon
          src="/icons/sit.svg"
          :class="selected ? 'sit' : 'grayed-out'"
        />
        <div class="progress-labels-holder">
          <h6
            v-if="showPercent"
            class="small-semi no-margin"
            :class="{'grayed-out': !selected}"
          >
            {{ Math.floor(percent1) }}%
          </h6>
          <b-form-input
            v-if="editmode"
            v-model="sitTime"
            :state="sitIsValid"
            type="time"
            size="sm"
            @update="updateSit"
          />
          <h6
            v-else
            :class="[{'grayed-out': !selected}, {'single-label': !showPercent}, showPercent ? 'small-regular' : 'small-semi']"
            :style="{'margin: auto 0;': !showPercent}"
          >
            {{ timeString(timeMsOne) }}
          </h6>
        </div>
      </div>
      <div class="label--wrapper right">
        <div class="progress-labels-holder">
          <h6
            v-if="showPercent"
            class="small-semi no-margin"
            :class="{'grayed-out': !selected}"
          >
            {{ Math.floor(percent2) }}%
          </h6>

          <b-form-input
            v-if="editmode"
            v-model="standTime"
            :state="standIsValid"
            type="time"
            size="sm"
            @update="updateStand"
          />
          <h6
            v-else
            :class="[{'grayed-out': !selected}, {'single-label': !showPercent}, showPercent ? 'small-regular' : 'small-semi']"
          >
            {{ timeString(timeMsTwo) }}
          </h6>
        </div>
        <svg-icon
          src="/icons/stand.svg"
          :class="selected ? 'stand' : 'grayed-out'"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { BFormInput } from 'bootstrap-vue';
import ExtendedDate from '../../classes/extended.date.class';

export default {
  components: {
    BFormInput,
  },

  props: {
    timeMsOne: {
      type: Number,
      default: 0,
    },
    timeMsTwo: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPercent: {
      type: Boolean,
      required: false,
      default: false,
    },
    editmode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      offset: 0.2,
      percentOne: this.timeMsOne,
      percentTwo: this.timeMsTwo,
      sitTime: '00:00',
      standTime: '00:00',
      sitIsValid: null,
      standIsValid: null,
    };
  },
  computed: {
    getSit() { return this.sitTime; },
    getStand() { return this.standTime; },
    percentOffset() {
      return this.percentOne;
    },
    linearGradientStyle() {
      return `var(--free-color) ${this.percentOffset - this.offset}%,`
            + `transparent ${this.percentOffset - this.offset}%,`
            + `transparent ${this.percentOffset + this.offset}%,`
            + `var(--space-icon-color) ${this.percentOffset + this.offset}%`;
    },
    percent1() {
      const val = this.percentOne;
      if (val % 0.5 >= 0.5) { return Math.ceil(val); }
      return Math.floor(val);
    },
    percent2() {
      const val = this.percentTwo;
      if (val % 0.5 >= 0.5) { return Math.ceil(val); }
      return Math.floor(val);
    },
  },
  watch: {
    timeMsOne() { this.setPercentages(); },
    timeMsTwo() { this.setPercentages(); },
  },
  created() {
    this.setPercentages();

    this.sitTime = new ExtendedDate(this.timeMsOne).UTCTimeString();
    this.standTime = new ExtendedDate(this.timeMsTwo).UTCTimeString();
  },
  methods: {
    setPercentages() {
      let total = this.timeMsOne + this.timeMsTwo;
      total = total === 0 ? 1 : total; // to make sure we do not divide with 0
      this.percentOne = (this.timeMsOne / total) * 100;
      this.percentTwo = (this.timeMsTwo / total) * 100;

      // Edge-case, if both values are 0, we want them to split the distributionbar
      if (this.timeMsOne === 0 && this.timeMsTwo === 0) {
        this.percentOne = 50;
        this.percentTwo = 50;
      }
    },
    updateSit(val) {
      if (val.length < 0) return;
      if (val.length <= 0) this.sitIsValid = false;

      const wholeDay = 1000 * 60 * 60 * 24;

      if (this.millisFromTimestring(val) + this.millisFromTimestring(this.standTime) > wholeDay) {
        this.sitTime = new ExtendedDate(wholeDay - this.millisFromTimestring(this.standTime)).UTCTimeString();
      } else {
        this.sitTime = val;
      }
      const millis = this.millisFromTimestring(this.sitTime);
      if (millis < 60000) {
        this.sitIsValid = false;
      } else {
        this.sitIsValid = null;
      }

      this.$emit('updated-sit-time', millis);
    },
    updateStand(val) {
      if (val.length < 0) return;
      if (val.length <= 0) this.standIsValid = false;

      const wholeDay = 1000 * 60 * 60 * 24;

      if (this.millisFromTimestring(val) + this.millisFromTimestring(this.sitTime) > wholeDay) {
        this.standTime = new ExtendedDate(wholeDay - this.millisFromTimestring(this.sitTime)).UTCTimeString();
      } else {
        this.standTime = val;
      }
      const millis = this.millisFromTimestring(this.standTime);
      if (millis < 60000) {
        this.standIsValid = false;
      } else {
        this.standIsValid = null;
      }

      this.$emit('updated-stand-time', millis);
    },
    millisFromTimestring(timestring) {
      const regexPattern = /^[0-9]{2}:[0-9]{2}$/; // Needs to match format '00:00'
      if (!regexPattern.test(timestring)) return 0;

      const time = timestring.split(':');
      const hours = parseInt(time[0], 10);
      const minutes = parseInt(time[1], 10);

      return hours * 3600000 + minutes * 60000;
    },
    timeString(millis) {
      const hour = 1000 * 60 * 60;
      const minute = 1000 * 60;
      const second = 1000;

      const hours = Math.floor(millis / hour);
      const minutes = Math.floor((millis - (hours * hour)) / minute);
      const seconds = Math.floor((millis - (minutes * minute)) / second);

      if (hours === 0 && minutes === 0) return `${seconds}s`;
      if (hours > 0 && minutes === 0) return `${hours}h`;
      if (hours === 0) return `${minutes}${minutes === 1 ? 'min' : 'mins'}`;
      return `${hours}h ${minutes}${minutes === 1 ? 'min' : 'mins'}`;
    },
  },
};

</script>

<style lang="scss">

  $height: 2.5rem;

  .goal-distributionbar__container {
    height: $height;
    position: relative;

    .grayed-out {
      background-color: var(--placeholder-font-color);
    }

    .goal-distributionbar__background {
      border: 2px solid #D9D9D9;
      border-radius: 0.5rem;
      position: absolute;
      height: $height;
      width: 100%;
    }

    .goal-distributionbar__foreground {
      border-radius: 0.5rem;
      position: absolute;
      height: $height;
      width: 100%;
    }
  }

  .label--wrapper {
    display: flex;

    .grayed-out {
      color: var(--placeholder-font-color);
      transition: color 0.2s ease;
    }

    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }

    .sit {
      transition: fill 0.2s ease;
      fill: var(--free-color);
    }
    .stand {
      transition: fill 0.2s ease;
      fill: var(--space-icon-color);
    }
    .grayed-out {
      transition: fill 0.2s ease;
      fill: var(--placeholder-font-color);
    }

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .goal-distributionbar__values-holder {
    justify-content: space-between;
    margin-top: 1rem;
    display: flex;

    .progress-labels-holder {
      flex-direction: column;
      margin: 0 1rem;
      display: flex;

      .single-label {
        margin: auto 0;
      }
    }
  }

</style>
