<template>
  <div class="reminder-timer__container margin margin-medium-large margin-top margin-bottom">
    <template v-if="timerActive">
      <ProgressBarCircle
        :percentage="percentage"
        :size="260"
        :progress-color="progressBarColor"
        :stroke-width="30"
        :box-shadow="true"
        :pulsate="shouldPulsate"
      >
        <template v-if="reminderState === REMINDER_STATE_ACTIVE">
          <div class="reminder-time-wrapper">
            <h6 class="reminder-time xx-big-bold">
              {{ timerLeft }}
            </h6>
            <p class="reminder-subtext tiny-medium">
              {{ $t('Components.Wellness.left_until') }}
            </p>
          </div>
        </template>

        <template v-if="reminderState === REMINDER_STATE_FINISHED && !isMoving">
          <div class="reminder-time-wrapper">
            <h6 class="reminder-time xx-big-bold">
              {{ $t('Components.Wellness.time_up') }}
            </h6>
            <p class="reminder-subtext tiny-medium">
              {{ $t('Components.Wellness.change_position') }}
            </p>
          </div>
        </template>

        <!--<template v-if="reminderState === REMINDER_STATE_NOT_CONNECTED">
          <h6 class="reminder-time xx-big-bold">
            {{ $t('Components.Wellness.not_connected') }}
          </h6>
        </template> -->

        <template v-if="reminderState === REMINDER_STATE_SNOOZE">
          <div class="reminder-time-wrapper">
            <h6 class="reminder-time xx-big-bold">
              {{ timerLeft }}
            </h6>
            <p class="reminder-subtext tiny-medium">
              {{ $t('Components.Wellness.snooze') }}
            </p>
          </div>
        </template>
      </ProgressBarCircle>
    </template>

    <template v-else>
      <ProgressBarCircle
        :percentage="0"
        :size="260"
        :stroke-width="30"
        :box-shadow="true"
      >
        <Button
          variant="pastel-primary"
          type="button"
          size="round medium"
          @click="$emit('onClick')"
        >
          {{ $t('Components.Wellness.start') }}
        </Button>
      </ProgressBarCircle>
    </template>
  </div>
</template>

<script>
/** Model */
import ReminderStateSingleton, {
  REMINDER_STATE_FINISHED, REMINDER_STATE_NOT_CONNECTED, REMINDER_STATE_NOT_ACTIVE, REMINDER_STATE_ACTIVE, REMINDER_STATE_SNOOZE, RING_COLOR_NOT_ACTIVE, RING_COLOR_ACTIVE,
} from '@/singletons/reminder.state.singleton';
import ConnectState from '@/singletons/connect.state.singleton';

/** Components */
import Button from '@/components/button/button.vue';
import ProgressBarCircle from './circle.component.vue';

export default {
  components: {
    Button,
    ProgressBarCircle,
  },

  data() {
    return {
      ReminderStateSingleton,
      ConnectState,
      REMINDER_STATE_FINISHED,
      REMINDER_STATE_NOT_CONNECTED,
      REMINDER_STATE_ACTIVE,
      REMINDER_STATE_SNOOZE,
    };
  },

  computed: {

    isMoving() { return ConnectState.selectedDevice && ConnectState.selectedDevice.isAutoMoving; },

    position() { return ConnectState.selectedDevice && ConnectState.selectedDevice.position; },

    shouldPulsate() {
      return ReminderStateSingleton.reminder.state === REMINDER_STATE_FINISHED;
    },

    /** Might use in later versions */
    ringColor() {
      return ReminderStateSingleton.reminder.state === REMINDER_STATE_NOT_ACTIVE ? RING_COLOR_NOT_ACTIVE : RING_COLOR_ACTIVE;
    },

    reminderState() {
      return ReminderStateSingleton.reminder.state;
    },

    timerActive() {
      return ReminderStateSingleton.profile?.reminder;
    },

    isTimerRunning() {
      return ReminderStateSingleton.reminder?.state !== REMINDER_STATE_NOT_ACTIVE;
    },

    timerLeft() {
      return ReminderStateSingleton.reminder.timeLeftAsString;
    },

    percentage() {
      return this.isTimerRunning ? ReminderStateSingleton.reminder.percent : 100;
    },

    progressBarColor() {
      return ReminderStateSingleton.reminder.color;
    },
  },
};
</script>

<style lang="scss" scoped>
.reminder-timer__container {
  display: flex;
  justify-content: center;
  align-items: center;
  .reminder-time-wrapper {
    display: flex;
    flex-direction: column;
    .reminder-subtext {
      margin: 0 auto;
    }
  }
  .reminder-time {
    align-self: center;
    font-variant-numeric: tabular-nums;
  }
}
</style>
